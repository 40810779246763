/* eslint-disable no-param-reassign */
import descriptions from './info.json';

let lang;
const supportedLang = ['en', 'fr', 'nl'];

const navLang = navigator.language;

switch (true) {
  case navLang.includes('en'):
    lang = 'en';
    break;
  case navLang.includes('nl'):
    lang = 'nl';
    break;
  case navLang.includes('fr'):
    lang = 'fr';
    break;
  default:
    // default language is English
    lang = 'en';
    break;
}

// Handle lang parameter if present, takes precedence over navigator language
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

if (urlParams.has('lang')) {
  const reqLang = urlParams.get('lang');

  if (supportedLang.includes(reqLang)) {
    lang = reqLang;
  } else {
    lang = 'en';
  }
}

/**
 * Standard Update of the contents of a page to a given language
 * Can be overridden by the eventlistener for 'newLang' for page specific contents
 * @param {String} language The new language
 */
function updateLang(language) {
  // Change text contents
  document.querySelectorAll('*[data-text]').forEach((element) => {
    const { text } = element.dataset;
    element.textContent = descriptions[language][text];
  });

  // Change html contents
  // (e.g., color links, bold in text, ...)
  document.querySelectorAll('*[data-html]').forEach((element) => {
    const { html } = element.dataset;
    element.innerHTML = descriptions[language][html];
  });

  lang = language;
  window.lang = lang;

  // update href on links
  document.getElementById('disclaimerLink').href = `./disclaimer.html?lang=${lang}`;
  document.getElementById('faqLink').href = `./faq.html?lang=${lang}`;
  document.getElementById('headerLink').href = `/?lang=${lang}`;
  document.getElementById('homeLink').href = `/?lang=${lang}`;
  document.getElementById('headerHomeButton').href = `/?lang=${lang}`;

  // New event so each page can handle it accordingly to their own needs
  // To translate page specific content (e.g., email placeholder on test page)
  window.dispatchEvent(new Event('newLang'));
}

// Set the language
window.lang = lang;
updateLang(lang);

// Event listeners for language buttons
document.getElementById('enButton').addEventListener('click', () => {
  updateLang('en');
});

document.getElementById('frButton').addEventListener('click', () => {
  updateLang('fr');
});

document.getElementById('nlButton').addEventListener('click', () => {
  updateLang('nl');
});
