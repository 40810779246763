{
    "en": {
      "mainTitle": "Test the cybersecurity of your organization in less than 10 seconds!",
      "startButton": "START TEST",
      "introductionTitle": "How cyber-secure are you?",
      "introductionSubTitle": "Take the cybersecurity self-test to evaluate your organization's security measures!",
      "introductionBody1": "It's uncommon to leave your front door wide open. If it happens even once, it's easily noticeable, and you will have to rely on a friendly neighbor to alert you or close the door for you. Conversely, vulnerabilities in your digital environment are often much harder to detect and may remain easily unnoticed for the untrained eye. This is precisely why this cybersecurity self-test was created. Think of it as a digital neighbor who has your back.",
      "introductionBody2": "<p>Check your organization's <strong> current cybersecurity status with just one click </strong>. The test results offer insights into potential vulnerabilities, security risks, and your overall security level.</p>",
      "introductionBody3": "<p>A good neighbor always offers valuable advice. Following your results, you will receive specific <strong>recommendations</strong> on steps to enhance your cybersecurity.</p>",
      "userInfoTitle": "What we know about you:",
      "progress": "Test progress:",
      "systemInfo": "System information",
      "ipInfo": "IP address",
      "locInfo": "GeoIP Location",  
      "secureDNS.apt.categoryName": "APT",
      "secureDNS.apt.description": "The test identifies indicators of APTs, which are classified as long-term and targeted cyber attacks.",
      "secureDNS.apt.longdescription": "An Advanced Persistent Threat (APT) is a sophisticated, long-term cyber attack where an intruder gains unauthorized access to a network, remaining undetected for an extended period. APTs typically prioritize data theft over causing network damage, making them challenging to detect and posing serious risks to the security of sensitive information.",
      "secureDNS.apt.recommendation": "APTs pose a formidable challenge due to their high level of sophistication. Nevertheless, organizations can implement preventive measures. Training staff is crucial, especially given that many APTs infiltrate companies through emails. Additionally, promptly installing software updates is essential to minimize the risk of APTs. Seeking the expertise of a cybersecurity professional ensures a robust defense for the company network.",
      "secureDNS.phishing.categoryName": "Phishing",
      "secureDNS.phishing.description": "This test identifies vulnerabilities that could be exploited in phishing attacks or attempts to steal sensitive information. Global cybersecurity experts consistently curate lists of malicious domains, and our test incorporates the most recent versions of these lists. The test evaluates the accessibility of these malicious domains from your network.",
      "secureDNS.phishing.longdescription": "Phishing is a form of cyber attack wherein deceptive messages, typically sent via email, aim to deceive individuals into divulging sensitive information or engaging in compromising actions. These messages often mimic legitimate sources, enticing recipients to click on malicious links or disclose confidential data, including passwords or financial information.",
      "secureDNS.phishing.recommendation": "Phishing primarily occurs through digital communication channels like email, text, or chat messages. Maintaining a skeptical approach, verifying senders, refraining from hasty link clicks, and exercising caution with attachments are crucial practices for security. Human errors happen, even with well-trained employees. Effective prevention involves regular awareness training and robust security solutions capable of intercepting suspicious messages.",
      "secureDNS.botnet.categoryName": "Botnets",
      "secureDNS.botnet.description": "The test ensures that your device is not exploited for malicious activities by actively checking unauthorized access to botnet command and control points.",
      "secureDNS.botnet.longdescription": "A botnet refers to a network of compromised devices or equipment infected by malicious software. This software performs specific actions without the knowledge of the device owners or users. The interconnected nature of this network makes it susceptible to a range of cyber attacks, including distributed denial of service attacks (DDoS), spam distribution, and the propagation of malware.",
      "secureDNS.botnet.recommendation": "To detect botnets, it is crucial to monitor network traffic for anomalies. Additionally, employing a firewall can help block unauthorized traffic, safeguarding your organization against botnet infiltrations.",
      "secureDNS.scam.categoryName": "Scam",
      "secureDNS.scam.description": "This test identifies fraudulent websites. The goal of fraud analysis is to identify, detect, and prevent any potentially fraudulent online activities.",
      "secureDNS.scam.longdescription": "Online scams involve fraudulent activities initiated over the Internet with the intention of deceiving users for financial gain or acquiring sensitive information. Scammers employ social engineering and other various tactics, including fake websites, misleading emails, or online advertisements, to trick unsuspecting individuals into divulging personal information, making payments, or clicking on malicious links.",
      "secureDNS.scam.recommendation": "Similar to phishing, maintaining vigilance against online fraud, commonly known as scams, is crucial. Users should always verify the authenticity of websites handling sensitive data by clicking on the lock icon at the top of the search bar. Additionally, it is recommended to implement measures to block traffic to and from known scam sites, which reduces the risk of employees inadvertently falling victim to online fraud.",
      "secureDNS.spam.categoryName": "Spam",
      "secureDNS.spam.description": "With this test, we assess whether your personal information is being shared with external websites without your awareness or consent.",
      "secureDNS.spam.longdescription": "Spam refers to unsolicited, irrelevant and often malicious messages sent in bulk through various online communication channels, including email, social media, and other messaging platforms. These messages typically aim to mislead recipients, promote questionable products or services, or distribute malware.",
      "secureDNS.spam.recommendation": "To prevent spam, keeping your email address and mobile number private is paramount. Yet, for organizations that must remain accessible to customers, achieving complete privacy is not possible. Therefore, the use of advanced spam filters is recommended. Such filters proactively block emails before they reach a user’s inbox, thereby providing robust protection against spam-related risks.",
      "secureDNS.malware.categoryName": "Malware",
      "secureDNS.malware.description": "The test identifies suspicious or malicious software attempting to infiltrate your computer while visiting specific websites.",
      "secureDNS.malware.longdescription": "Malware refers to malicious software specifically designed to disrupt, damage, or gain unauthorized access to computer systems or networks. Malware exists in various forms, including viruses, worms, Trojan horses, ransomware and spyware, and can be used to steal sensitive information or inflict damages on your systems.",
      "secureDNS.malware.recommendation": "Malware has evolved in sophistication, rendering traditional antivirus tools less effective against advanced threats. To address this, new classes of security software have been developed, known as 'Endpoint Detection and Response' (EDR) and Extended Detection and Response (XDR), innovative solutions that analyze computer behavior, often leveraging artificial intelligence, to detect and proactively block anomalies.",
      "eicar.file.categoryName": "Viruses",
      "eicar.file.description": "A test file named EICAR is used to verify the functionality of the security software on your device. This test confirms whether the software is both present and activated. The EICAR Anti-Virus Test File, developed by the European Institute for Computer Antivirus Research, derives its name from its origin. This test assesses your anti-virus solution's ability to detect and manage malicious content, simulating its response to an actual virus. Since it's a simulation, testing can occur without any risk of damage.",
      "eicar.file.longdescription": "A virus is a form of malicious software capable of replicating and spreading to other files and programs within a computer or network. Once activated, a virus can execute malicious code, resulting in data damage or deletion, disruption of business processes, and unauthorized access to the infected system.",
      "eicar.zip.categoryName": "Hidden viruses in a ZIP",
      "eicar.zip.description": "As part of a test, an EICAR file was concealed within ZIP files to assess whether your security software can identify and manage potentially harmful hidden content.",
      "eicar.zip.longdescription": "A virus concealed within a ZIP file refers to malicious software hidden within a compressed file format (ZIP) to evade detection by security software. This technique enables attackers to distribute malicious content, bypassing specific security measures, as the virus remains dormant until the file is unzipped.",
      "eicar.tar.categoryName": "Hidden viruses in a TAR",
      "eicar.tar.description": "As part of the cybersecurity self-test, an EICAR file was concealed within a compressed TAR file. This test aims to determine whether your security software can identify potentially harmful hidden content.",
      "eicar.tar.longdescription": "A virus concealed within a TAR file (Tape Archive) denotes a form of malicious software hidden within a TAR archive file—a widely used format for compressing and archiving files. This technique allows attackers to conceal malicious content, making it challenging for security systems to detect the virus until the TAR file is extracted. Once extracted, the user's data and systems become compromised.",
      "eicar.rar.categoryName": "Hidden viruses in a RAR",
      "eicar.rar.description": "An EICAR file was hidden within a compressed RAR file as part of a test to determine whether your computer's security software can identify and manage potentially harmful hidden content.",
      "eicar.rar.longdescription": "A virus concealed within a RAR file (Roshal Archive) denotes a form of malicious software hidden within a RAR archive file—a widely used format for compressing, archiving, and distributing data. This technique allows attackers to obscure malicious content, making it challenging for security systems to detect the virus until the RAR file is extracted. Upon extracting the file, several security risks may arise.",
      "eicar.zipzip.categoryName": "Viruses in nested ZIPs",
      "eicar.zipzip.description": "As part of the cybersecurity self-test, an EICAR file was concealed within a nested structure of ZIP files. This test aims to determine whether your security software can identify potentially harmful hidden content.",
      "eicar.zipzip.longdescription": "A virus concealed within multiple layers of ZIP files (Ziczac Inline Pin) refers to a form of malicious software hidden in successive compressed ZIP files. Attackers use this method to obscure the virus, increasing the difficulty for security systems to detect malicious content before the nested ZIP files are extracted. Once this extraction occurs, the user's data and systems become compromised.",
      "eicar.recommendation": "While installing a reliable anti-virus program at the computer and network level is a common recommendation to block viruses, it is no longer sufficient in today's digital landscape where privacy and encryption are widespread. Therefore, we strongly recommend implementing SSL inspection to detect viruses that may reach users through HTTPS.",
      "aggrText" : "Overall test results",
      "question1": "How does the test work?",
      "question2": "No difficult questionnaires and 100% reliable",
      "answer2.1": "This cybersecurity self-test is intended for individuals and organizations with limited familiarity in cybersecurity practices. No prior knowledge is required, and there's no need to tackle complex questionnaires.",
      "answer2.2": "The cybersecurity self-test is entirely safe for your computer and network. Nothing is installed on your computer during the test.",
      "question3": "Important notice",
      "answer3": "Rest assured, your privacy is our priority. Our cybersecurity self-test is entirely free of charge and poses no risk to your device or network. Upon completion, you'll instantly receive a convenient summary of the results. For a more in-depth analysis and tailored recommendations, you can request a detailed PDF report via email. This service is complimentary and comes with no obligations!",
      "question4": "What is being tested?",
      "answer4": "The test assesses your degree of protection against online threats, such as spam, scams, viruses, phishing, botnets, and malware. A detailed assessment is available in the PDF report, which you can request after completing the test.",
      "aboutUsTitle": "This cybersecurity self-test was developed by AXS Guard",
      "aboutUsBody1": "AXS Guard, a Belgian cybersecurity specialist, has been developing advanced security solutions since 1996. Their commitment is to provide \"cybersecurity without worries\", prioritizing a secure online environment for individuals, networks, and applications.",
      "aboutUsBody2": "<p class=\"px-6\"> Eager to learn more about AXS Guard's technologies and services? Visit <a class=\"text-AXSBlue\" href=\"https://www.axsguard.com/\">www.axsguard.com</a> </p>",
      "aboutUsPoint1": "Years of experience",
      "aboutUsPoint2": "Belgian",
      "aboutUsPoint3": "Stopped attacks daily",
      "testAmountSubTitle": "(number of tests)",
      "greenAdviceHeading": "Expert opinion: Looks like you are doing well!",
      "greenAdviceBody1": "Our cybersecurity self-test revealed few or no weaknesses. Congratulations! Your understanding of the significance of robust cyber hygiene is commendable.",
      "greenAdviceBody2": "Nevertheless, it's crucial to recognize that this assessment provides only a snapshot and doesn't ensure future security. We advise conducting more in-depth analyses to delve into additional security aspects.",
      "orangeAdviceHeading": "Expert opinion: It seems there is room for improvement.",
      "orangeAdviceBody": "Our test suggests the presence of certain vulnerabilities in your cybersecurity that require attention. Specific details about these weaknesses and recommended actions can be found in your report. To receive your report in PDF format, please provide your email address.",
      "redAdviceHeading": "Expert opinion: Urgent action required. Your cybersecurity is at risk, and immediate improvements are necessary.",
      "redAdviceBody": "Critical vulnerabilities have been identified, necessitating not only further analyses but also immediate consultation with an IT professional for further guidance.",
      "emailFormTitle": "The next step? Receive your recommendation in PDF?",
      "emailFormBody": "<p> If you prefer to receive a more <strong>detailed analysis</strong> of the test results and our recommendations, you can request the full report in PDF format via email. This report is also free of charge and without any obligation!</p>", 
      "disclaimerCheck": "<span id=\"disclaimerText\" class=\"block text-sm font-semibold text-gray-900\">I agree to the terms in the <a class=\"text-AXSBlue\" href=\"./disclaimer.html?lang=nl\"> disclaimer</a></span>",
      "newsletterCheck": "I would like to receive notifications about updates and subscribe to the newsletter.",
      "emailButton": "Yes, send me the PDF report with my test results!",
      "emailPlaceholder": "Company email",
      "invalidMail": "Invalid email address!",
      "invalidMailDescr": "You must provide a business email address",
      "disclaimerTitle": "Disclaimer",
      "disIntro": "This cybersecurity self-test is intended for informational purposes only and as a tool to assess the current cybersecurity status on your network and computer. The use of this test is subject to the following conditions and restrictions:",
      "dis1Head": "Cost",
      "dis1Body": "Using the CyberselfTest is free of charge.",
      "dis2Head": "No guarantee of security",
      "dis2Body": "While this self-test is designed to identify potential security risks and vulnerabilities, we cannot guarantee complete identification of all security issues. It is crucial to explore additional security measures and seek professional advice.",
      "dis3Head": "No substitute for Professional Advice",
      "dis3Body": "The results of this test are not a replacement for professional security advice. It is strongly recommended to consult qualified experts for a thorough assessment of your cybersecurity status and to address any identified risks comprehensively.",
      "dis4Head": "User responsibility",
      "dis4Body": "The user is solely responsible for the use of this self-test and the interpretation of its results. We disclaim any liability for any direct or indirect damage, loss, or consequences arising out of the use of test results.",
      "dis5Head": "Data & privacy",
      "dis5Body": "This cybersecurity self-test may gather data concerning your system and network for analytical purposes. All collected data will be treated confidentially and utilized solely for the assessment of your current cybersecurity status. No personal information will be shared or disclosed without your explicit consent.",
      "dis6Head": "Recommendations and improvements",
      "dis6Body": "The results of the cybersecurity self-test may include recommendations for security improvements. The decision to implement these recommendations is at the user's discretion.",
      "dis7Head": "Intellectual property rights",
      "dis7Body": "The content of this website is protected by intellectual property rights. You are authorized to access this website for your personal use and for internal business use. If materials are provided to you as a download or by email, you have the right to download and copy those materials free of charge, as well as the right to make those materials available to third parties free of charge, on the condition that you do not modify the materials. For specific materials, you may be granted additional rights. Such specific rights will be explicitly stated in the respective materials.",
      "dis8Head": "Legal notice",
      "dis8Body": "\"cybertselftest.be\" (and subdomains) is a website and tool provided by Able bv, with its registered office at B-2800 Mechelen (Belgium), Dellingstraat 28B, company number 0457.938.087, and can be contacted at info@axsguard.com.",
      "disOutro1": "By using this test tool, you agree to the above terms and conditions. If you do not agree to these terms and conditions, please refrain from using this test tool.",
      "disOutro2": "<p> For questions or comments, please contact us at <a href=\"mail:info@axsguard.com\" class=\"text-AXSBlue\"> info@axsguard.com </a>.</p>",
      "disOutro3": "Date of last revision: 27/11/2023",
      "homeButton": "Homepage",
      "faqTitle": "Frequently asked questions",
      "faqq1": "Who is this CyberSelfTest for?",
      "faqa1Intro": "This test can be useful for a wide range of audiences, depending on the specific purpose and focus of the test.",
      "faqa1h1": "Companies and organizations",
      "faqa1b1": "Organizations can use the cybersecurity self-test to assess the present cybersecurity status of their internal networks and systems, which will pinpoint vulnerabilities and offer recommendations for enhancing security measures.",
      "faqa1h2": "Individuals:",
      "faqa1b2": "Individual users, especially those who are cyber-aware, can use this test to check their own computers and devices to improve their personal cybersecurity.",
      "faqa1h3": "IT departments:",
      "faqa1b3": "IT professionals can use the cybersecurity self-test as part of their periodic security assessments and audits to measure compliance with their organization's security guidelines.",
      "faqa1h4": "Cybersecurity companies & MSSPs",
      "faqa1b4": "Companies specialising in cybersecurity can present this tool as a service to their customers to evaluate their current cybersecurity status.",
      "faqa1h5": "Training:",
      "faqa1b5": "Schools and training institutions can use this cybersecurity self-test as an educational tool to teach students about cybersecurity practices and show them how to improve their own security.",
      "faqq2": "I get a red screen when starting the test. What now?",
      "faqa2": "This test assesses your resilience against the latest and most recent cyber threats, including new vulnerabilities, which can lead to unpredictable browser behavior. To bypass potential mislabeling, click on 'Details' first and 'Visit this site' afterwards. We assure you that this test is completely safe, and no software will be installed on your device. For additional details, please refer to the home page and disclaimer.",
      "faqq3": "Where can I get additional information?",
      "faqa3": "<p>Contact us via email: <a href=\"mailto:support@axsguard.com\" class=\"text-AXSBlue\">support@axsguard.com</a> or call: <a href=\"tel:+3215504400\" class=\"text-AXSBlue\">+32 15 50 44 00</a>",
      "faqq5": "What does the test cost?",
      "faqa5": "The test is completely free of charge.",
      "thankyouPage": "Thank you for using and sharing this tool!",
      "emailSuccessTitle": "Congratulations on your first step in the safe direction!",      
      "emailSuccessBody1": "<p>You have taken the initiative to critically examine your cybersecurity, an important first step in raising cyber awareness. As we work together to create a safer digital environment for everyone, <strong> we encourage you to share this page (not your test results) with friends, colleagues, and acquaintances </strong>.</p>",
      "emailSuccessBody2": "<p>If you have any questions about this tool or your test results, feel free to contact AXS Guard, the creator of this cybersecurity self-test, at <a href=\"mailto:support@axsguard.com\" class=\"text-AXSBlue\">support@axsguard.com</a>. We are ready to assist you in enhancing your cybersecurity and ensuring your online safety.</p>",
      "emailSuccessSpam": "Make sure to check your spam folder if you cannot find the PDF report in your inbox.",
      "emailSuccessSafeDay": "Have a safe day!",
      "emailLearnMore": "More information on AXS Guard:",
      "blogBox": "Blog",
      "blogTitle": "Stay informed with the latest updates from the AXS Guard blog.",
      "siteBox": "Website",
      "siteTitle": "Learn more about our solutions by exploring our website.",
      "partnersBox": "Partners",
      "partnersTitle": "Interested? Find one of our many partners to learn more.",
      
      "pdfScoreText": "Your overall score: ",
      "pdfGreenIntroTitle": "You're doing well!",
      "pdfGreenIntro1": "Our cybersecurity self-test revealed few to no weaknesses, showcasing your strong awareness of good cyber hygiene.",
      "pdfGreenIntro2": "However, bear in mind that this is just a snapshot and doesn't ensure future security. We advise regular evaluations and revisiting specific actions.",
      "pdfGreenIntro3": "In any case, we'd like to offer you a brief checklist. Regularly assessing the elements on this list and repeating certain actions is not an unnecessary luxury.",
      "pdfOrangeIntroTitle": "It appears there's room for improvement.",
      "pdfOrangeIntro1": "The self-test has highlighted some weaknesses that demand attention. Certain aspects of your cybersecurity need to be addressed, as your cyber hygiene can be enhanced!",
      "pdfOrangeIntro2": "Please note that our cybersecurity self-test provides a snapshot and offers indications rather than guarantees. The results, while valuable, are not as precise as a comprehensive analysis. We strongly recommend conducting more in-depth analyses covering multiple security aspects.",
      "pdfOrangeIntro3": "We've observed varying security levels with different scores. Pay urgent attention, particularly to subtests with scores below 50%, as addressing these points promptly is crucial for elevating your cybersecurity to a better level.",
      "pdfOrangeIntro4": "Please be aware that this test and advice are conceived to prevent attacks and their consequences. If security weaknesses are identified, there's a possibility that you already may have been compromised. To err on the side of caution, we strongly advise seeking the expertise of cybersecurity professionals.",
      "pdfOrangeIntro5": "In any case, we'd like to provide you with a brief checklist. Regularly evaluating the elements on this list and repeating certain actions is not an unnecessary luxury, regardless of your performance in specific areas. Remember, cybersecurity is a verb—an ongoing action that requires constant attention.",
      "pdfRedIntroTitle": "Oops. It appears that the level of your cybersecurity leaves much to be desired.",
      "pdfRedIntro1": "The cybersecurity self-test has revealed serious weaknesses, making it crucial not only to conduct additional analyses but also to promptly seek guidance from an IT professional in addressing these vulnerabilities.",
      "pdfRedIntro2": "Please note that our cybersecurity self-test provides a snapshot and offers indications rather than guarantees. The results, while valuable, are not as precise as a comprehensive analysis. We strongly recommend conducting more in-depth analyses covering multiple security aspects.",
      "pdfRedIntro3": "There is a possibility that your security level is worse than reported by this test. Subtests with the lowest scores, particularly those below 50%, require urgent attention. To elevate your cybersecurity to a better level, the results with the lowest scores need to be addressed immediately.",
      "pdfRedIntro4": "Please be aware that this test and advice are conceived to prevent attacks and their consequences. If security weaknesses are identified, there's a possibility that you already may have been compromised. To err on the side of caution, we strongly advise seeking the expertise of cybersecurity professionals.",
      "pdfRedIntro5": "In any case, we'd like to provide you with a brief checklist. Regularly evaluating the elements on this list and repeating certain actions is not an unnecessary luxury, regardless of your performance in specific areas. Remember, cybersecurity is a verb—an ongoing action that requires constant attention.",
      "pdfSmallCheckListTitle": "Points of Attention / Mini-Checklist:",
      "pdfSmallCheckList1": "Are your spam filters up to date?",
      "pdfSmallCheckList2": "Do you have EDR/XDR in place?",
      "pdfSmallCheckList3": "Is your antivirus solution up to date?",
      "pdfSmallCheckList4": "Always use HTTPS.",
      "pdfSmallCheckList5": "Verify the authenticity of websites and senders.",
      "pdfSmallCheckList6": "Is DNS filtering enabled?",
      "pdfSmallCheckList7": "Is your firewall configuration OK?",
      "pdfSmallCheckList8": "Are you monitoring unusual activities on your network?",
      "pdfSmallCheckList9": "Don't delay installing software updates.",
      "pdfSmallCheckList10": "Implement SSL inspection.",
      "pdfLargeCheckListTitle": "Additional tips:",
      "pdfLargeCheckList1": "Organize regular threat awareness training for all employees, not just technical staff. Foster awareness of online dangers, teaching skepticism towards messages and senders and how to recognize anomalies. Conduct this training at least once a year or once every two years, considering the evolving nature of cyber threats and potential turnover in your organization.",
      "pdfLargeCheckList2": "Consider pen testing or hiring an ethical hacker to identify weaknesses, providing valuable insights and awareness for users.",
      "pdfLargeCheckList3": "Conduct regular analyses of your network, infrastructure, and security level to ensure ongoing robustness.",
      "pdfLargeCheckList4": "Consider the NIS 2 directive as a 'best practice' guide, even if your organization is not legally mandated to comply.",
      "pdfLargeCheckList5": "Prioritize prevention: In cybersecurity, prevention is better than cure. A robust prevention strategy can save you significant trouble.",
      "pdfLargeCheckList6": "Opt for Managed Security Services: Due to the intricate and rapidly evolving nature of cybersecurity, it's advisable to delegate the day-to-day management of your infrastructure and network to industry experts.",
      "pdfTestCategoryExpl": "More information:",
      "pdfTestDisclosure": "Assessment",
      "pdfRecommendations": "Professional Guidance" ,
      "pdfIntroMSSP": "Contact AXS Guard: Observe & Protect, AXS Guard's Managed Security Services offering, caters to both small and large organizations. Additionally, if desired, we are more than willing to connect your organization with a suitable IT partner who understands your needs and is familiar with your IT environment.",
      "pdfOutro": "If you have questions about cybersecurity, our recommendations, or if you'd like to connect with experienced IT integrators, please reach out to us at info@axsguard.com."
    },
    "fr": {
      "mainTitle": "Testez votre cybersécurité en moins de 10 secondes!",
      "startButton": "Démarrer le test",
      "introductionTitle": "Quel est le score de votre entreprise en matière de cybersécurité?",
      "introductionSubTitle": "Faites l'autotest de cybersécurité pour évaluer les mesures de sécurité au sein de votre organisation!",
      "introductionBody1": "Il est rare de laisser sa porte d'entrée grande ouverte. Cela serait facile à repérer. Si cela se produit ne serait-ce qu'une seule fois, vous devrez compter sur un voisin sympathique pour vous alerter ou fermer la porte à votre place. À l’inverse, les vulnérabilités d'un système informatique sont souvent beaucoup plus difficiles à repérer et peuvent facilement échapper à l'attention des observateurs non avertis. C’est précisément pour cette raison que cet autotest de cybersécurité a été conçu. Considérez-le comme un voisin numérique qui veille sur vous.",
      "introductionBody2": "<p>Vérifiez <strong>l'état actuel de la cybersécurité de votre organisation en un seul clic</strong>. Les résultats des tests vous offriront un aperçu des vulnérabilités potentielles et de votre niveau général de sécurité.</p>",
      "introductionBody3": "<p> Un bon voisin offre toujours de précieux conseils. Suite à vos résultats, vous recevrez des <strong>recommandations</strong> spécifiques sur les étapes à suivre pour améliorer votre niveau de sécurité informatique.</p>",
      "userInfoTitle": "Collecte des informations:",
      "progress": "Progression des tests:",
      "systemInfo": "Informations système",
      "ipInfo": "Adresse IP",
      "locInfo": "Localisation GeoIP",  
      "secureDNS.apt.categoryName": "APT",
      "secureDNS.apt.description": "Le test identifie les indicateurs de menaces persistantes avancées, qui sont classés comme cyberattaques ciblées à long terme.",
      "secureDNS.apt.longdescription": "Une menace persistante avancée (APT) est une cyberattaque sophistiquée à long terme par laquelle un intrus obtient un accès non autorisé à un réseau, sans être détecté pendant une période prolongée. Les APT prioritisent généralement le vol de données plutôt que l’infliction de dommages, ce qui les rend difficiles à détecter et pose de sérieux risques pour la sécurité des informations sensibles.",
      "secureDNS.apt.recommendation": "Les APT représentent un défi redoutable en raison de leur haut niveau de sophistication. Cependant, il existe des mesures préventives efficaces. Compte tenu de la propension des APT à infiltrer les entreprises par le biais d'e-mails, la formation du personnel revêt une importance cruciale. Il est également essentiel de tenir à jour tous vos logicielles pour minimiser les risques. Faire appel à l'expertise d'un professionnel de la cybersécurité est fortement conseillé pour améliorer votre cyber-résilience.",
      "secureDNS.phishing.categoryName": "Phishing",
      "secureDNS.phishing.description": "Ce test identifie les vulnérabilités susceptibles d'être exploitées lors d'attaques de phishing ou de tentatives de vol d'informations sensibles. Les experts en cybersécurité établissent régulièrement des listes de domaines malveillants, et notre test intègre les versions les plus récentes de ces listes. Il évalue l'accessibilité de ces domaines malveillants depuis votre réseau.",
      "secureDNS.phishing.longdescription": "L'hameçonnage ou le phishing est une forme de cyberattaque dans laquelle des messages trompeurs, généralement envoyés par courrier électronique, visent à inciter les individus à divulguer des informations sensibles ou à s'engager dans des actions compromettantes. Ces messages imitent souvent des sources légitimes, incitant les destinataires à cliquer sur des liens malveillants ou à divulguer des données confidentielles, notamment des mots de passe ou des informations financières.",
      "secureDNS.phishing.recommendation": "Le phishing se produit principalement via les e-mails, les SMS ou les messages de chat. Maintenir une approche sceptique, vérifier les expéditeurs, s'abstenir de cliquer hâtivement sur des liens et faire preuve de prudence avec les pièces jointes sont des pratiques cruciales pour la cybersécurité. Des erreurs humaines sont inévitables, même parmi des employés bien formés. Une prévention efficace passe par des formations de sensibilisation régulières et des solutions de sécurité robustes, capables d'intercepter les messages suspects.",
      "secureDNS.botnet.categoryName": "Botnets",
      "secureDNS.botnet.description": "Le test garantit que votre appareil n'est pas exploité à des fins malveillantes en vérifiant activement les accès non autorisés à des serveurs de commande et de contrôle (C&C) repertoriés.",
      "secureDNS.botnet.longdescription": "Un botnet est un groupe d'ordinateurs ou de dispositifs sous le contrôle d'un attaquant, utilisé pour mener des activités malveillantes. Au sein du botnet, des actions spécifiques sont exécutées à l'insu des propriétaires ou des utilisateurs des appareils. La nature interconnectée de ce réseau le rend vulnérable à divers types de cyberattaques, notamment les attaques par déni de service distribué (DDoS), la distribution de spam et la propagation de logiciels malveillants.",
      "secureDNS.botnet.recommendation": "Pour détecter les botnets, il est crucial de surveiller votre trafic réseau à la recherche d’anomalies. De plus, l'utilisation d'un pare-feu peut aider à bloquer le trafic non autorisé, protégeant ainsi votre organisation contre les infiltrations liées aux botnets.",
      "secureDNS.scam.categoryName": "Arnaques",
      "secureDNS.scam.description": "Ce test identifie les sites Web frauduleux. Son objectif est d’identifier, de détecter et de prévenir toute activité en ligne potentiellement frauduleuse.",
      "secureDNS.scam.longdescription": "Les escroqueries en ligne impliquent des activités frauduleuses en ligne dans le but d’arnaquer les utilisateurs ou d'acquérir des informations sensibles. Les fraudeurs ont recours à l'ingénierie sociale et à d'autres tactiques diverses, notamment des sites Web frauduleux, des messages trompeurs avec de fausses demandes de paiement ou des publicités en ligne, pour inciter des utilisateurs non avertis à divulguer des informations personnelles, à effectuer des paiements ou à cliquer sur des liens malveillants.",
      "secureDNS.scam.recommendation": "Tout comme pour le phishing, il est essentiel de rester vigilant face à la fraude en ligne. Il est fortement conseillé de toujours vérifier l'authenticité des sites Web en consultant l'icône de verrouillage située en haut de la barre de recherche. De plus, il est recommandé de mettre en place des mesures pour bloquer le trafic vers des sites frauduleux connus, réduisant ainsi le risque que vos employés deviennent des victimes involontaires des fraudes en ligne.",
      "secureDNS.spam.categoryName": "Spam",
      "secureDNS.spam.description": "Ce test vise à déterminer si vos informations personnelles sont partagées avec des sites Web externes à votre insu ou sans votre consentement.",
      "secureDNS.spam.longdescription": "Le spam se réfère à des messages non sollicités, souvent malveillants et non pertinents, diffusés en masse à travers divers canaux de communication en ligne, tels que le courrier électronique, les réseaux sociaux et d'autres plateformes de messagerie. Ces messages ont généralement pour objectif de tromper les destinataires, de promouvoir des produits ou services douteux, ou de propager des logiciels malveillants.",
      "secureDNS.spam.recommendation": "Pour contrer le spam, il est essentiel de protéger votre adresse e-mail et votre numéro de mobile. Cependant, pour les organisations qui doivent rester accessibles à leurs clients, assurer une confidentialité totale peut s'avérer difficile. Dans de tels cas, l'utilisation de filtres anti-spam avancés est recommandée. Ces filtres bloquent de manière proactive les e-mails avant qu'ils n'atteignent la boîte de réception de l'utilisateur, offrant ainsi une protection robuste contre les risques liés au spam.",
      "secureDNS.malware.categoryName": "Malware",
      "secureDNS.malware.description": "Le test détecte les logiciels suspects ou malveillants qui tentent de s'infiltrer dans votre ordinateur lors de la visite de certains sites Web spécifiques.",
      "secureDNS.malware.longdescription": "Les logiciels malveillants englobent une catégorie de programmes spécifiques conçus pour perturber, endommager ou obtenir un accès non autorisé aux systèmes informatiques. Ils se manifestent sous différentes formes, telles que les virus, les vers informatiques, le cheval de Troie, les ransomwares et les logiciels espions, et peuvent être exploités pour extorquer des informations sensibles ou causer des dommages à vos systèmes.",
      "secureDNS.malware.recommendation": "Les logiciels malveillants ont évolué en termes de sophistication, dépassant les capacités des outils antivirus traditionnels. En réponse à ce défi, de nouvelles catégories de logiciels de sécurité ont émergé, connues sous le nom de « Endpoint Detection and Response » (EDR) et Extended Detection and Response (XDR). Ces solutions innovantes analysent le comportement des ordinateurs, souvent en utilisant l'intelligence artificielle, pour détecter et bloquer de manière proactive les anomalies.",
      "eicar.file.categoryName": "Virus informatiques",
      "eicar.file.description": "Le fichier de test EICAR est spécifiquement conçu à des fins de test pour évaluer la réactivité des logiciels de sécurité antivirus. Ce test confirme si votre logiciel antivirus est à la fois présent et activé. Le fichier de test EICAR est une chaîne de caractères, écrite dans un fichier informatique, destiné à tester le bon fonctionnement des logiciels antivirus. Ce test évalue la capacité de votre solution antivirus à détecter et à gérer le contenu malveillant, en simulant sa réponse à un virus réel. Puisqu'il s'agit d'une simulation, ce test ne pose aucun risque.",
      "eicar.file.longdescription": "Un virus est une forme de logiciel malveillant capable de se répliquer et de se propager à d'autres fichiers et programmes au sein d'un ordinateur ou d'un réseau. Une fois activé, un virus peut exécuter du code malveillant, entraînant des dommages ou une suppression de données, une interruption de travail et un accès non autorisé au système infecté.",
      "eicar.zip.categoryName": "Virus cachés dans un ZIP",
      "eicar.zip.description": "Un fichier EICAR a été dissimulé dans un fichier ZIP afin d'évaluer si votre logiciel de sécurité peut l’identifier et gérer correctement.",
      "eicar.zip.longdescription": "Un virus dissimulé dans un fichier ZIP fait référence à un logiciel malveillant caché dans un format de fichier compressé (ZIP) pour échapper à la détection par un logiciel de sécurité. Cette technique permet aux attaquants de distribuer des fichiers malveillants, en contournant des mesures de sécurité spécifiques, car le virus reste inactif jusqu'à son extraction.",
      "eicar.tar.categoryName": "Virus cachés dans un TAR",
      "eicar.tar.description": "Dans le cadre de l'autotest de cybersécurité, un fichier EICAR a été dissimulé dans un fichier TAR compressé. Ce test vise à déterminer si votre logiciel de sécurité peut identifier les virus dissimulés.",
      "eicar.tar.longdescription": "Un virus dissimulé dans un fichier TAR (Tape Archive) désigne une forme de logiciel malveillant caché dans un fichier d'archive TAR, un format largement utilisé pour compresser et archiver des données. Cette technique permet aux attaquants de dissimuler le contenu malveillant, ce qui le rend difficile à détecter jusqu'à ce qu’il soit extrait. Une fois extrait, les données et les systèmes de l'utilisateur sont susceptibles d’être compromis.",
      "eicar.rar.categoryName": "Virus cachés dans un RAR",
      "eicar.rar.description": "Un fichier EICAR a été masqué dans un fichier RAR dans le cadre d'un test visant à déterminer si le logiciel de sécurité de votre ordinateur peut l’identifier et le gérer correctement.",
      "eicar.rar.longdescription": "Un virus dissimulé dans un fichier RAR (Roshal Archive) désigne une forme de logiciel malveillant caché dans un fichier d'archive RAR, un format largement utilisé pour compresser, archiver et distribuer des données. Cette technique permet aux attaquants de masquer le contenu malveillant, ce qui le rend difficile à détecter jusqu'à ce qu’il soit extrait. Lors de l'extraction du fichier, plusieurs risques de sécurité peuvent survenir.",
      "eicar.zipzip.categoryName": "Virus cachés dans des ZIPs",
      "eicar.zipzip.description": "Dans le cadre de l’autotest de cybersécurité, un fichier EICAR a été dissimulé dans une structure imbriquée de fichiers ZIP. Ce test vise à déterminer si votre logiciel de sécurité peut identifier les fichiers cachés et potentiellement dangereux.",
      "eicar.zipzip.longdescription": "Un virus dissimulé dans plusieurs couches de fichiers ZIP (Ziczac Inline Pin) fait référence à une forme de logiciel malveillant caché dans des fichiers ZIP compressés successifs. Les attaquants utilisent cette méthode pour masquer le virus, augmentant ainsi la difficulté pour les systèmes de sécurité de le détecter avant son extraction. Une fois l’extraction effectuée, les données et les systèmes de l'utilisateur sont susceptibles d'être compromis.",
      "eicar.recommendation": "Bien que l'installation d'un programme antivirus fiable au niveau de l'ordinateur et du réseau soient des recommandations courantes, elles ne suffisent plus dans le paysage numérique actuel où la confidentialité et le cryptage sont fort répandus. Par conséquent, nous recommandons fortement de mettre en œuvre l’inspection HTTPS (déchiffrement des flux SSL/TLS) pour détecter les virus susceptibles d'atteindre les utilisateurs par le protocole HTTPS.",
      "aggrText" : "Résultats d'exécution de test",
      "question1": "Comment le test fonctionne-t-il?",
      "question2": "Pas de questionnaires difficiles et 100% fiable",
      "answer2.1": "Cet autotest de cybersécurité est conçu pour les individus et les organisations ayant une connaissance limitée des pratiques de cybersécurité. Aucune connaissance préalable n'est requise, et vous n'aurez pas à répondre à des questionnaires complexes.",
      "answer2.2": "L'autotest de cybersécurité est sans risques pour votre ordinateur et votre réseau. Aucune installation de logiciel n'est effectuée ni avant ni pendant le test.",
      "question3": "Avis important",
      "answer3": "Rassurez-vous, la confidentialité de vos données est notre priorité. Notre autotest de cybersécurité est entièrement gratuit et ne présente aucun risque pour votre appareil ou votre réseau. Une fois terminé, vous recevrez instantanément un résumé pratique des résultats. Pour une analyse plus approfondie et des recommandations personnalisées, vous pouvez demander un rapport PDF détaillé par e-mail. Ce service est gratuit et sans engagement!",
      "question4": "Qu'est-ce qui est testé?",
      "answer4": "Ce test évalue votre niveau de protection contre les menaces en ligne telles que le spam, les escroqueries, les virus, le phishing, les botnets et les logiciels malveillants. Une évaluation détaillée est disponible dans le rapport PDF, que vous pouvez demander après avoir terminé le test.",
      "aboutUsTitle": "Cet autotest de cybersécurité a été conçu par AXS Guard.",
      "aboutUsBody1": "AXS Guard, expert en cybersécurité basé en Belgique, conçoit des solutions de sécurité avancées depuis 1996. Leur engagement consiste à offrir une \"cybersécurité sans soucis\", mettant l'accent sur la création d'un environnement en ligne sécurisé pour les utilisateurs, les réseaux et les applications.",
      "aboutUsBody2": "<p class=\"px-6\"> Désireux d’en savoir plus sur les technologies et les services d’AXS Guard? Visitez <a class=\"text-AXSBlue\" href=\"https://www.axsguard.com/\">www.axsguard.com</a> </p>",
      "aboutUsPoint1": "Années d'expérience",
      "aboutUsPoint2": "belge",
      "aboutUsPoint3": "Attaques détecte et bloque par jour",
      "testAmountSubTitle": "(nombre de tests)",
      "greenAdviceHeading": "Avis d'expert : Il semble que votre cybersécurité actuelle soit en bon état!",
      "greenAdviceBody1": "Notre autotest de cybersécurité n'a révélé que peu de failles. Toutes nos félicitations! Vous semblez bien comprendre l'importance d'une cyberhygiène solide.",
      "greenAdviceBody2": "Cependant, veuillez prendre conscience que cette évaluation ne fournit qu'un instantané de votre sécurité cybernétique et ne garantit aucunement votre sécurité future. Nous vous recommandons de réaliser des analyses plus approfondies afin d'explorer tous les aspects de votre cybersécurité.",
      "orangeAdviceHeading": "Avis d'expert : Il semble qu'il y ait place à amélioration.",
      "orangeAdviceBody": "Notre test suggère la présence de certaines vulnérabilités qui requièrent une attention particulière. Vous trouverez les détails spécifiques et les actions recommandées dans votre rapport. Pour recevoir ce dernier au format PDF, veuillez nous fournir votre adresse e-mail.",
      "redAdviceHeading": "Avis d'expert : Action urgente requise. Votre cybersécurité est menacée, et des améliorations immédiates s'imposent.",
      "redAdviceBody": "Des vulnérabilités critiques ont été identifiées, nécessitant non seulement des analyses approfondies, mais également une consultation immédiate avec un professionnel de l'informatique pour obtenir des conseils supplémentaires.",
      "emailFormTitle": "La prochaine étape ? Recevez vos recommandations au format PDF.",
      "emailFormBody": "<p>Si vous souhaitez obtenir <strong> une analyse </strong> approfondie des résultats des tests ainsi que de nos <strong> recommandations </strong>, n'hésitez pas à demander le rapport complet au format PDF par <strong> e-mail </strong>. Ce rapport est également gratuit et sans aucun engagement! </p>", 
      "disclaimerCheck": "<span id=\"disclaimerText\" class=\"block text-sm font-semibold text-gray-900\">J'accepte les termes de la <a class=\"text-AXSBlue\" href=\"./disclaimer.html?lang=nl\"> clause de non-responsabilité </a></span>",
      "newsletterCheck": "Je souhaite recevoir des notifications sur les mises à jour et m'abonner à la newsletter.",
      "emailButton": "Oui, je désire recevoir le rapport au format PDF!",
      "emailPlaceholder": "Contact e-mail de votre entreprise",
      "invalidMail": "Adresse e-mail invalide!",
      "invalidMailDescr": "Veuillez fournir une adresse e-mail professionnelle.",
      "disclaimerTitle": "Clause de non-responsabilité",
      "disIntro": "Cet autotest de cybersécurité est destiné à des fins informatives et constitue un outil permettant d'évaluer l'état de votre cybersécurité actuelle. L'utilisation de ce test est soumise aux conditions et restrictions suivantes:",
      "dis1Head": "Frais",
      "dis1Body": "L’utilisation de l’autotest de cybersécurité est gratuite.",
      "dis2Head": "Aucune garantie de sécurité",
      "dis2Body": "Bien que cet autotest ait été conçu pour identifier les risques potentiels et les vulnérabilités, nous ne pouvons garantir l'identification complète de tous les problèmes de sécurité cybernétique sur votre appareil ou dans votre réseau. Il est essentiel d'explorer des mesures de sécurité supplémentaires et de solliciter l'avis d'un professionnel.",
      "dis3Head": "Rien ne remplace les conseils d'un professionnel",
      "dis3Body": "Les résultats de ce test ne substituent aucunement les conseils en matière de cybersécurité offerts par un expert qualifié. Il est fortement recommandé de consulter un professionnel pour une évaluation approfondie et pour traiter de manière globale tout risque identifié.",
      "dis4Head": "Responsabilités de l'utilisateur",
      "dis4Body": "L'utilisateur est seul responsable de l'utilisation de cet autotest et de l'interprétation de ses résultats. Nous déclinons toute responsabilité quant à toute conséquence directe ou indirecte résultant de l'utilisation des résultats des tests.",
      "dis5Head": "Confidentialité des données",
      "dis5Body": "Cet autotest de cybersécurité peut recueillir des données concernant votre système et votre réseau à des fins d'analyse. Toutes les données collectées seront traitées de manière confidentielle et utilisées uniquement pour l'évaluation de votre cybersécurité. Aucune information personnelle ne sera partagée ou divulguée sans votre consentement.",
      "dis6Head": "Recommandations et améliorations",
      "dis6Body": "Les résultats de l'autotest de cybersécurité peuvent inclure des recommandations visant à renforcer votre cybersécurité. Il appartient à l'utilisateur de décider de mettre en œuvre ou non ces recommandations.",
      "dis7Head": "Droits de propriété intellectuelle:",
      "dis7Body": "Ce site Internet est protégé par les droits de propriété intellectuelle. Vous êtes autorisé à accéder à ce site Web à des fins personnelles et pour un usage professionnel interne. Si des données vous sont offertes par téléchargement ou via courrier électronique, vous avez le droit de les télécharger et de les copier gratuitement, ainsi que le droit de les mettre à la disposition de tiers gratuitement, à condition de ne pas en modifier le contenu. Pour certains documents et fichiers spécifiques, des droits supplémentaires peuvent vous être accordés, lesquels seront explicitement indiqués dans les documents respectifs.",
      "dis8Head": "Mentions légales:",
      "dis8Body": "\"cybertselftest.be\" (et ses sous-domaines) est un site hébergé par Able bv, dont le siège social est situé à B-2800 Malines (Belgique), Dellingstraat 28B, numéro d'entreprise 0457.938.087. Adresse e-mail : info@axsguard.com.",
      "disOutro1": "En utilisant notre site, vous confirmez que vous acceptez les présentes conditions d'utilisation et que vous vous engagez à les respecter.",
      "disOutro2": "<p> Pour toute question ou commentaire, veuillez nous contacter par e-mail: <a href=\"mail:info@axsguard.com\" class=\"text-AXSBlue\"> info@axsguard.com </a>.</p>",
      "disOutro3": "Date de dernière révision : 27/11/2023",
      "homeButton": "Page d'accueil",
      "faqTitle": "Questions fréquemment posées (FAQ)",
      "faqq1": "À qui s’adresse cet autotest de cybersécurité?",
      "faqa1Intro": "Ce test s'adresse à tous, selon vos objectifs et intérêts spécifiques.",
      "faqa1h1": "Entreprises et organisations:",
      "faqa1b1": "Les entreprises ont la possibilité d'utiliser l'autotest de cybersécurité pour évaluer l'état actuel de leur sécurité informatique. Celui-ci repère les vulnérabilités potentielles et propose des recommandations en vue d'améliorer leurs mesures de sécurité.",
      "faqa1h2": "Utilisateurs:",
      "faqa1b2": "Les utilisateurs individuels, notamment ceux sensibilisés à la cybersécurité, peuvent utiliser ce test pour évaluer leurs propres ordinateurs et appareils, contribuant ainsi à renforcer leur sécurité personnelle.",
      "faqa1h3": "Professionnels de l'informatique:",
      "faqa1b3": "Les experts en informatique peuvent intégrer l'autotest à leurs évaluations et audits périodiques de sécurité. Cet outil d'auto-évaluation leur permet de mesurer la conformité aux directives de sécurité d'une organisation.",
      "faqa1h4": "Services de cybersécurité gérés (MSSP):",
      "faqa1b4": "Les entreprises spécialisées dans la cybersécurité peuvent proposer cet outil en tant que service à leurs clients afin d'évaluer leur niveau actuel en matière de cybersécurité.",
      "faqa1h5": "Formations:",
      "faqa1b5": "Les écoles et les établissements de formation peuvent utiliser cet autotest de cybersécurité comme un outil pédagogique pour enseigner aux étudiants les bonnes pratiques en matière de cybersécurité.",
      "faqq2": "Je vois un écran rouge après avoir démarré le test. Et maintenant?",
      "faqa2": "Ce test évalue votre résilience face aux cybermenaces les plus récentes, incluant les nouvelles vulnérabilités susceptibles d'engendrer un comportement imprévisible du navigateur. Afin d'éviter toute erreur potentielle, veuillez commencer par cliquer sur « Détails », puis sur « Visiter ce site ». Nous vous assurons que ce test est totalement sûr et qu'aucun logiciel ne sera installé sur votre appareil. Pour plus de détails, veuillez consulter la page d'accueil et la clause de non-responsabilité.",
      "faqq3": "Où puis-je obtenir des informations supplémentaires?",
      "faqa3": "<p>Contactez-nous par e-mail: <a href=\"mailto:support@axsguard.com\" class=\"text-AXSBlue\">support@axsguard.com</a> ou appelez <a href=\"tel:+3215504400\" class=\"text-AXSBlue\">+32 15 50 44 00</a>",
      "faqq5": "Combien coûte le test?",
      "faqa5": "Le test est entièrement gratuit.",
      "thankyouPage": "Merci d'utiliser et de partager cet outil !",
      "emailSuccessTitle": "Félicitations pour votre premier pas dans la bonne direction!",      
      "emailSuccessBody1": "<p>Vous avez pris l'initiative d'examiner d'un œil critique votre cybersécurité, une première étape importante dans la sensibilisation à la cybersécurité. Alors que nous travaillons ensemble pour créer un environnement numérique plus sûr, nous vous encourageons à <strong> partager cette page (et non les résultats de vos tests) avec vos amis, collègues et connaissances. </strong> </p>",
      "emailSuccessSpam": "Assurez-vous de vérifier votre dossier spam si vous ne trouvez pas le rapport PDF dans votre boîte de réception.",
      "emailSuccessBody2": "<p>Si vous avez des questions quelconques concernant cet outil ou les résultats de vos tests, n'hésitez pas à contacter AXS Guard, le créateur de cet autotest de cybersécurité, à l'adresse suivante : <a href=\"mailto:support@axsguard.com\" class=\"text -AXSBlue\">support@axsguard.com</a>. Nous sommes toujours prêts à vous aider à améliorer votre cybersécurité et à garantir votre sécurité en ligne. </p>",
      "emailSuccessSafeDay": "Passez une bonne journée en toute sécurité!",
      "emailLearnMore": "Plus d’informations sur AXS Guard:",
      "blogBox": "Blog",
      "blogTitle": "Restez informé des dernières mises à jour grâce à notre blog AXS Guard",
      "siteBox": "Site web",
      "siteTitle": "Découvrez davantage sur nos solutions en explorant notre site Web.",
      "partnersBox": "Partenaires AXS Guard",
      "partnersTitle": "Vous êtes intéressé(e) ? Trouvez l'un de nos nombreux partenaires pour obtenir plus d'informations.",
      "pdfScoreText": "Votre score global:",
      "pdfGreenIntroTitle": "Vous vous débrouillez bien!",
      "pdfGreenIntro1": "Notre auto-test de cybersécurité n'a révélé que peu ou aucune faiblesse, démontrant ainsi votre solide conscience d'une bonne cyber-hygiène.",
      "pdfGreenIntro2": "Cependant, gardez à l'esprit qu'il ne s'agit que d'un constat instantané sans garanties de sécurité future. Nous recommandons des évaluations régulières et de réexaminer fréquemment votre situation.",
      "pdfGreenIntro3": "Dans tous les cas, nous aimerions vous proposer une brève liste de contrôle. Évaluer régulièrement les éléments de cette liste et répéter certains gestes n’est pas un luxe inutile.",
      "pdfOrangeIntroTitle": "Il semble qu'il y ait place à l’amélioration.",
      "pdfOrangeIntro1": "L’autotest a mis en lumière certaines faiblesses qui nécessitent votre attention. Certains aspects de votre cybersécurité méritent d'être abordés, car votre cyberhygiène peut être améliorée!",
      "pdfOrangeIntro2": "Cependant, gardez à l'esprit qu'il ne s'agit que d'un constat instantané sans garanties de sécurité future. Nous recommandons des évaluations régulières et de réexaminer fréquemment votre situation.",
      "pdfOrangeIntro3": "Nous avons constaté divers niveaux de sécurité avec des scores variés. Accordez une attention particulière, surtout aux sous-tests dont les scores sont inférieurs à 50 %, car il est crucial de traiter ces points rapidement afin d'améliorer significativement votre cybersécurité.",
      "pdfOrangeIntro4": "Sachez que ces tests et conseils sont conçus pour prévenir les attaques et en minimiser les conséquences. Si des failles de sécurité sont identifiées, il est possible que votre système ait déjà été compromis. Par mesure de précaution, nous vous recommandons vivement de solliciter l'expertise de professionnels.",
      "pdfOrangeIntro5": "Dans tous les cas, nous aimerions vous proposer une brève liste de contrôle. Évaluer régulièrement les éléments de cette liste et répéter certains gestes n’est pas un luxe inutile. N'oubliez pas que la cybersécurité est un processus continu qui nécessite une vigilance constante et une adaptation aux nouvelles menaces.",
      "pdfRedIntroTitle": "Oops. Il semble que le niveau de votre cybersécurité laisse beaucoup à désirer.",
      "pdfRedIntro1": "L'autotest de cybersécurité a révélé des faiblesses graves, nécessitant des analyses supplémentaires de toute urgence, ainsi que l'intervention d'un professionnel de l'informatique pour remédier à ces vulnérabilités.",
      "pdfRedIntro2": "Veuillez noter que notre autotest de cybersécurité offre seulement un aperçu instantané et fournit des indications plutôt que des garanties. Bien que les résultats soient un indicateur important, ils ne sont pas aussi détaillés qu'une analyse complète. Nous recommandons fortement de réaliser des analyses plus approfondies couvrant plusieurs aspects de votre cybersécurité.",
      "pdfRedIntro3": "Il est possible que votre niveau de sécurité soit pire que celui indiqué par ce test. Les sous-tests avec les scores les plus bas, en particulier ceux inférieurs à 50 %, nécessitent une attention urgente. Afin d'améliorer votre niveau de cybersécurité, il est impératif de traiter immédiatement les points affichant les scores les plus bas.",
      "pdfRedIntro4": "Sachez que ces tests et conseils sont élaborés pour prévenir les attaques et atténuer leurs conséquences. Si des failles de sécurité sont identifiées, il est possible que votre système ait déjà été compromis. Par mesure de précaution, nous vous recommandons vivement de solliciter l'expertise de professionnels.",
      "pdfRedIntro5": "Dans tous les cas, nous aimerions vous proposer une brève liste de contrôle. Évaluer régulièrement les éléments de cette liste et répéter certains gestes n’est pas un luxe inutile. N'oubliez pas que la cybersécurité est un processus continu qui nécessite une vigilance constante et une adaptation aux nouvelles menaces.",
      "pdfSmallCheckListTitle": "Points d’attention / Mini-liste de contrôle:",
      "pdfSmallCheckList1": "Vos filtres anti-spam sont-ils à jour?",
      "pdfSmallCheckList2": "Avez-vous mis en place un EDR/XDR?",
      "pdfSmallCheckList3": "Votre solution antivirus est-elle à jour?",
      "pdfSmallCheckList4": "Utilisez toujours le protocole HTTPS.",
      "pdfSmallCheckList5": "Vérifiez l'authenticité des sites Web et des expéditeurs.",
      "pdfSmallCheckList6": "Le filtrage DNS est-il activé?",
      "pdfSmallCheckList7": "La configuration de votre pare-feu est-elle correcte?",
      "pdfSmallCheckList8": "Surveillez-vous les activités inhabituelles sur votre réseau?",
      "pdfSmallCheckList9": "N'attendez pas pour installer les mises à jour logicielles.",
      "pdfSmallCheckList10": "Implémentez l'inspection HTTPS.",
      "pdfLargeCheckListTitle": "Conseils supplémentaires:",
      "pdfLargeCheckList1": "Organisez régulièrement des formations de sensibilisation aux menaces pour tous les employés. Enseignez le scepticisme envers les messages et les expéditeurs, ainsi que la reconnaissance des anomalies. Planifiez cette formation au moins une fois par an, voire une fois tous les deux ans, en tenant compte de la nature évolutive des cybermenaces et du roulement potentiel du personnel au sein de votre organisation.",
      "pdfLargeCheckList2": "Envisagez de procéder à des tests d'intrusion ou de faire appel à un hacker éthique pour identifier les faiblesses, fournir des informations précieuses tout en sensibilisant les utilisateurs.",
      "pdfLargeCheckList3": "Effectuez des analyses régulières de votre réseau, de votre infrastructure et de votre niveau de sécurité pour garantir une robustesse continue.",
      "pdfLargeCheckList4": "Considérez la directive NIS 2 comme un guide de 'bonnes pratiques', même si votre organisation n'est pas légalement tenue de s'y conformer.",
      "pdfLargeCheckList5": "Donnez la priorité à la prévention : en matière de cybersécurité, il vaut mieux prévenir que guérir. Une stratégie de prévention solide peut vous éviter de graves problèmes.",
      "pdfLargeCheckList6": "Optez pour des services de sécurité gérés : en raison de la nature complexe et évolutive de la cybersécurité, il est conseillé de déléguer la gestion quotidienne de votre infrastructure et de votre réseau à des experts en la matière.",
      "pdfTestCategoryExpl": "Informations complémentaires:",
      "pdfTestDisclosure": "Qu'avons-nous testé?",
      "pdfRecommendations": "Conseils d'experts:",
      "pdfIntroMSSP": "Contactez AXS Guard : Observe & Protect, l'offre de services de sécurité gérés d'AXS Guard, s'adresse tant aux petites qu'aux grandes organisations. De plus, si vous le souhaitez, nous sommes plus que disposés à vous mettre en relation avec un partenaire informatique de confiance qui comprendra vos besoins.",
      "pdfOutro": "Si vous avez des questions sur la cybersécurité, nos conseils, ou si vous souhaitez entrer en contact avec des intégrateurs informatiques expérimentés, n'hésitez pas à nous écrire à info@axsguard.com. Nous sommes là pour vous aider !"
    },
    "nl": {
      "mainTitle": "Test de Cybersecurity van jouw onderneming in minder dan 10 seconden!",
      "startButton": "START TEST",
      "introductionTitle": "Hoe cyberveilig ben jij?",
      "introductionSubTitle": "Ontdek het met de CyberSelfTest!",
      "introductionBody1": "Het is niet erg gebruikelijk om je voordeur wagenwijd open te laten staan. Mocht dat toch een keer voorvallen, dan is dat erg zichtbaar en kan je alleen maar hopen dat een vriendelijke buur je verwittigt of de deur gaat sluiten. De gaten in je digitale omgeving zijn doorgaans moeilijker te spotten wanneer je er zelf geen kaas van gegeten hebt. Daarom deze CyberSelfTest. Beschouw deze test als je buur die je wijst op eventuele tekortkomingen.",
      "introductionBody2": "<p> Door een simpele klik op de knop wordt de <strong> huidige status van je cyberbeveiliging op je (bedrijfs)netwerk </strong> gemeten. De resultaten verschaffen inzichten in mogelijke kwetsbaarheden, beveiligingsrisico’s en je algemene beveiligingsniveau. </p>",
      "introductionBody3": "<p> Een goede buur geeft ook al eens goed advies: op basis van je resultaten ontvang je concrete <strong> aanbevelingen </strong> over de te nemen stappen om je cyberveiligheid naar een hoger niveau te tillen. </p>",
      "userInfoTitle": "Wat we van je weten:",
      "progress": "Voortgang test:",
      "systemInfo": "Systeeminformatie",
      "ipInfo": "IP-adres",
      "locInfo": "Locatie",  
      "secureDNS.apt.categoryName": "APT",
      "secureDNS.apt.description": "De test spoort tekenen op van APT’s / malware, die te categoriseren zijn als langdurige en gerichte cyberaanvallen.",
      "secureDNS.apt.longdescription": "Een geavanceerde aanhoudende dreiging of Advanced Persistent Threat (APT) is een geavanceerde langdurige cyberaanval waarbij een indringer ongeautoriseerde toegang tot een netwerk verkrijgt en gedurende een langere periode onopgemerkt blijft. APT's zijn vaak gericht op het stelen van gegevens in plaats van schade aan het netwerk of de organisatie te veroorzaken. Ze kunnen moeilijk te detecteren zijn en ernstige gevolgen hebben voor de beveiliging van gevoelige informatie.",
      "secureDNS.apt.recommendation": "APT’s zijn zeer geavanceerde bedreigingen waardoor ze moeilijk te vermijden zijn. Toch kunnen bedrijven enkele maatregelen nemen. Zo is het trainen van personeel belangrijk aangezien veel APT's een bedrijf binnenkomen via e-mails. Hiernaast is het van belang om steeds direct nieuwe software-updates te installeren om het risico op APT’s te minimaliseren. Verder raden we aan om contact op te nemen met een cybersecurity-expertt voor een waterdichte afsluiting van het bedrijfsnetwerk.",
      "secureDNS.phishing.categoryName": "Phishing",
      "secureDNS.phishing.description": "De test spoort kwetsbaarheden op die phishingaanvallen of pogingen mogelijk maken om jouw gevoelige informatie te stelen. Malafide domeinnamen worden op constante basis verzameld door wereldwijde cyber security experts. De test maakt gebruik van de meest recente lijsten met malafide domeinnamen. Er werd geverifieerd in welke mate deze domeinnamen bereikbaar zijn van op je netwerk.",
      "secureDNS.phishing.longdescription": "Phishing is een soort cyberaanval waarbij frauduleuze berichten, meestal via e-mail, worden verzonden om individuen te misleiden door gevoelige informatie te ontfutselen of door gebruikers aan te zetten tot andere compromitterende acties. Deze berichten lijken vaak afkomstig te zijn van legitieme bronnen, waardoor de ontvangers worden aangespoord om op kwaadaardige links te klikken of vertrouwelijke gegevens zoals wachtwoorden of financiële informatie te verstrekken.",
      "secureDNS.phishing.recommendation": "Phishing komt het meest voor via een digitaal communicatie medium zoals e-mail, SMS of chatberichten. Het is belangrijk om steeds sceptisch te zijn, afzenders te controleren, niet zomaar op links te klikken en steeds voorzichtig te zijn met bijlagen. Werknemers blijven mensen, waardoor het klikken op gevaarlijke links onvermijdelijk is. Als preventie is het belangrijk om naast een goede awareness training, ook goede beveiligingssoftware te hebben die phishing links op het netwerkniveau in de kiem smoren.",
      "secureDNS.botnet.categoryName": "Botnets",
      "secureDNS.botnet.description": "De test gaat na of je toestel niet wordt gebruikt voor kwaadaardige activiteiten. We controleren ongeautoriseerde toegang naar botnet-commando- en controlepunten.",
      "secureDNS.botnet.longdescription": "Een botnet is een netwerk van geïnfecteerde apparaten / apparatuur door kwaadaardige software. De software doet bepaalde acties zonder medeweten van de eigenaren of gebruikers. Dit netwerk kan op zich worden gebruikt voor verschillende cyberaanvallen, zoals gedistribueerde denial-of-service-aanvallen (DDoS of Distributed Denial of Service), het verzenden van spam of het verspreiden van malware.",
      "secureDNS.botnet.recommendation": "Om botnets te detecteren is het van vitaal belang om het netwerkverkeer te monitoren voor ongebruikelijke activiteiten. Hiernaast kan er een firewall gebruikt worden om ongeautoriseerd verkeer te blokkeren en je bedrijf zo te beschermen tegen botnet infiltraties.",
      "secureDNS.scam.categoryName": "Scam",
      "secureDNS.scam.description": "De test identificeert frauduleuze websites en checkt potentiële oplichting of andere online fraude.",
      "secureDNS.scam.longdescription": "Een internet zwendel  zijn frauduleuze activiteiten die via internet worden opgezet met als doel gebruikers te misleiden voor financieel gewin of om gevoelige informatie te verkrijgen. Oplichters gebruiken vaak verschillende tactieken, zoals valse websites, misleidende e-mails of online advertenties, om argeloze individuen in de val te laten lopen zodat zij persoonlijke gegevens verstrekken, betalingen uitvoeren of op kwaadaardige links klikken.",
      "secureDNS.scam.recommendation": "Net zoals bij phishing is het bij online fraude, zogenaamde scams, belangrijk om steeds waakzaam te blijven. Controleer hiervoor steeds de authenticiteit van websites die met gevoelige data omgaan door op het slotje te klikken bovenaan in de zoekbalk. Verder is het voor bedrijven nuttig om verkeer van bekende scam sites te blokkeren zodat medewerkers niet ongewild ten prooi vallen aan online fraude.",
      "secureDNS.spam.categoryName": "Spam",
      "secureDNS.spam.description": "De test checkt of je persoonlijke informatie wordt gedeeld met externe websites zonder je medeweten.",
      "secureDNS.spam.longdescription": "Internet spam verwijst naar ongevraagde en vaak schadelijke of niet-relevante berichten die in massa worden verzonden via verschillende online communicatiekanalen, zoals e-mail, sociale media of berichten platforms. Deze berichten zijn meestal bedoeld om ontvangers te misleiden, twijfelachtige producten of diensten te promoten of malware te verspreiden.",
      "secureDNS.spam.recommendation": "Om spam te ontwijken is het belangrijk om je e-mailadres / telefoonnummer zoveel mogelijk privé te houden. Dit is echter vaak niet mogelijk voor bedrijven die contacteerbaar moeten zijn voor potentiële klanten of het leveren van ondersteuning (support). Hiervoor bestaan er geavanceerde spamfilters die mails op het netwerkniveau blokkeren zodat ze zelfs nooit tot in een inbox raken.",
      "secureDNS.malware.categoryName": "Malware",
      "secureDNS.malware.description": "De test spoort verdachte of schadelijke software op die tracht binnen te sluipen in je computer wanneer je bepaalde websites bezoekt.",
      "secureDNS.malware.longdescription": "Malware verwijst naar kwaadaardige software die specifiek is ontworpen om systemen te verstoren, beschadigen of ongeautoriseerde toegang te verkrijgen tot computersystemen of netwerken. Malware kan verschillende vormen aannemen, waaronder virussen, wormen, Trojaanse paarden, ransomware en spyware, en kan worden gebruikt om gevoelige informatie te stelen of andere schadelijke effecten op je infrastructuur te veroorzaken.",
      "secureDNS.malware.recommendation": "Malware wordt alsmaar slimmer met de jaren en de tijden waar standaard anti-virus geavanceerde malware blokkeren, zijn voorbij. Hiervoor werden nieuwe klasses van beveiligingssoftware ontworpen genaamd ‘Endpoint Detection and Response’ (EDR) en Extended Detection and Response (XDR). Hier wordt het gedrag van computers geanalyseerd, vaak met behulp van artificiële intelligentie, om zo anomalieën op te sporen en te blokkeren.",
      "eicar.file.categoryName": "Virussen",
      "eicar.file.description": "Een gesimuleerd testbestand genaamd EICAR wordt gebruikt om te controleren of je huidige beveiligingssoftware van je computer (nog) zijn werk doet.  (als die software tenminste aanwezig en geactiveerd is) De EICAR Anti-Virus Test File werd ontwikkeld door het European Institute for Computer Antivirus Research, wat de naam van de test verklaart. Deze test gaat na of je anti-virus oplossing schadelijke inhoud kan detecteren en verwerken, vergelijkbaar met hoe het zou omgaan met een daadwerkelijk virus. Omdat het om een simulatie gaat, kan er wel getest worden, maar uiteraard geen schade aangericht worden.",
      "eicar.file.longdescription": "Een virus is een type kwaadaardige software die zichzelf kan repliceren en verspreiden naar andere bestanden en programma's op een computer of netwerk. Zodra geactiveerd , kan een virus verschillende schadelijke acties uitvoeren, zoals het beschadigen of verwijderen van gegevens, het verstoren van bedrijfsprocessen of het verkrijgen van ongeautoriseerde toegang tot het geïnfecteerde systeem.",
      "eicar.zip.categoryName": "Virus verborgen in een ZIP",
      "eicar.zip.description": "Een EICAR-bestand werd verborgen in gezipte ZIP-files om te kijken of je beveiligingssoftware potentieel schadelijke verborgen inhoud kan identificeren en beheren.",
      "eicar.zip.longdescription": "Een virus verborgen in een zip-bestand verwijst naar kwaadaardige software die verborgen is in een gecomprimeerd (ZIP) bestand om detectie door beveiligingssoftware te voorkomen. Deze techniek stelt aanvallers in staat schadelijke inhoud te verspreiden terwijl ze bepaalde beveiligingsmaatregelen omzeilen, omdat het virus inactief blijft totdat het ZIP-bestand is uitgepakt.",
      "eicar.tar.categoryName": "Virus verborgen in een TAR",
      "eicar.tar.description": "Een EICAR-bestand werd verborgen in een gecomprimeerde TAR als onderdeel van de CyberSelfTest. We onderzochten of je beveiligingssoftware potentieel schadelijke verborgen inhoud kan identificeren.",
      "eicar.tar.longdescription": "Een virus verborgen in een TAR-bestand (Tape Archive) verwijst naar een type kwaadaardige software die verborgen is in een TAR-archiefbestand, een veelgebruikt formaat voor het comprimeren en archiveren van bestanden. Aanvallers kunnen deze methode gebruiken om schadelijke inhoud te verbergen, waardoor het moeilijk is voor beveiligingssystemen om het virus te detecteren vooraleer het TAR-bestand is uitgepakt. Vanaf dat moment worden de gegevens van de gebruiker en diens systemen gecompromitteerd.",
      "eicar.rar.categoryName": "Virus verborgen in een RAR",
      "eicar.rar.description": "Een EICAR-bestand werd verborgen in een gecomprimeerd RAR-bestand als onderdeel van de CyberSelfTest om te onderzoeken of de beveiligingssoftware van je computer potentiële schadelijke verborgen inhoud kan identificeren en beheren.",
      "eicar.rar.longdescription": "Een virus verborgen in een RAR-bestand (Roshal Archive) verwijst naar een type kwaadaardige software die verborgen is in een RAR-archiefbestand, eveneens een veelgebruikt formaat voor het comprimeren, archiveren en distribueren van gegevens. Aanvallers kunnen deze methode gebruiken om schadelijke inhoud te verbergen, waardoor het moeilijk is voor beveiligingssystemen om het virus te detecteren voordat het RAR-bestand is uitgepakt. Eens het bestand wordt uitgepakt, kunnen er verschillende beveiligingsrisico's worden veroorzaakt.",
      "eicar.zipzip.categoryName": "Virus in een ZIP",
      "eicar.zipzip.description": "Een EICAR-bestand werd verborgen in een geneste structuur van zip-bestanden als onderdeel van de CyberSelfTest. We onderzochten of je beveiligingssoftware potentieel schadelijke verborgen inhoud kan identificeren en beheren.",
      "eicar.zipzip.longdescription": "Een virus verborgen in verschillende lagen van ZIP-bestanden (Ziczac Inline Pin) verwijst naar een type kwaadaardige software die verborgen is in meerdere lagen gecomprimeerde ZIP-bestanden. Deze methode wordt eveneens door aanvallers gebruikt om het virus te verbergen, waardoor het moeilijker wordt voor beveiligingssystemen om de kwaadaardige inhoud te detecteren vooraleer de geneste ZIP-bestanden zijn uitgepakt. Vanaf dat moment worden de gegevens van de gebruiker en diens systemen gecompromitteerd.",
      "eicar.recommendation": "De meest gangbare aanbeveling is om een betrouwbaar antivirusprogramma te installeren op computer en netwerkniveau zodat virussen geblokkeerd kunnen worden. In de huidige digitale wereld, waar privacy en encryptie alomtegenwoordig zijn, is dit echter niet meer voldoende. We raden daarom sterk aan om gebruik te maken van SSL inspectie om ook virussen te kunnen detecteren die via HTTPS een weg banen naar werknemers of gebruikers.",
      "aggrText" : "Algemene testresultaten",
      "question1": "Hoe werkt de test?",
      "question2": "Géén moeilijke vragenlijsten en 100% betrouwbaar",
      "answer2.1": "De CyberSelfTest is bedoeld voor mensen en bedrijven met beperkte expertise in cyberbeveiliging technieken. Je hoeft dus nul voorkennis te hebben of geen moeilijke vragenlijsten in te vullen.",
      "answer2.2": "De CyberSelfTest is compleet onschadelijk voor je computer en netwerk. Er wordt tijdens de CyberSelfTest niets geïnstalleerd op je computer.",
      "question3": "Belangrijke kanttekeningen",
      "answer3": "We garanderen 100% vertrouwelijkheid. De CyberSelfTest is volledig gratis. De beknopte resultaten krijg je onmiddellijk op je scherm te zien in een handig overzicht. Wil je een uitgebreidere analyse ontvangen over je resultaten en de aanbevelingen, dan kan je het rapport in PDF-vorm ontvangen per e-mail. Ook het ontvangen van dit rapport is gratis en vrijblijvend!",
      "question4": "Wat wordt geëvalueerd?",
      "answer4": "De uitgevoerde testonderdelen hebben betrekking op de bescherming tegen SPAM, Scam, Virussen, Phising, Botnets en Malware. Een beschrijving hiervan kan je terugvinden in het PDF rapport dat je ontvangt (indien gewenst) na de test.",
      "aboutUsTitle": "Deze CyberSelfTest werd ontwikkeld door AXS Guard",
      "aboutUsBody1": "AXS Guard maakt sinds 1996 geavanceerde security-oplossingen die mensen, netwerken en applicaties beschermen tegen cyberdreigingen. De Belgische Cyber Security Specialist staat voor “Cybersecurity zonder zorgen” en beschouwt een veilige online omgeving als haar topprioriteit.",
      "aboutUsBody2": "<p class=\"px-6\"> Wil je meer weten over de technologieën en services van AXS Guard?  Bezoek <a class=\"text-AXSBlue\" href=\"https://www.axsguard.com/\">www.axsguard.com</a> </p>",
      "aboutUsPoint1": "Jaar ervaring",
      "aboutUsPoint2": "Belgisch",
      "aboutUsPoint3": "Gestopte aanvallen per dag",
      "testAmountSubTitle": "(aantal tests)",
      "greenAdviceHeading": "Expertenadvies: Het lijkt erop dat jij goed bezig bent!",
      "greenAdviceBody1": "De CyberSelfTest heeft weinig tot geen zwakke punten aan het licht gebracht. Gefeliciteerd! Jij bent je bewust van het belang van een goede cyber hygiëne. ",
      "greenAdviceBody2": "Houd er echter rekening mee dat dit slechts een momentopname is en geen garantie biedt voor de toekomst. We raden je aan om diepere analyses uit te voeren die nog meerdere beveiligingsaspecten onderzoeken.",
      "orangeAdviceHeading": "Expertenadvies: Het lijkt erop dat er ruimte tot verbetering is.",
      "orangeAdviceBody": "Op basis van de CyberSelfTest zijn er duidelijk zwakheden in je cyberbeveiliging aan de oppervlakte gekomen die om aandacht vragen. Het is duidelijk dat er het één en ander aangepakt moet worden. Sommige van de geteste categorieën bestaan uit meerdere tests. Welke zwakheden dat dus precies zijn en wat je eraan kan doen, kan je terugvinden in je rapport. Om je rapport te ontvangen (in PDF-vorm), vul je als de bliksem je e-mail in.",
      "redAdviceHeading": "Expertenadvies: Oops. Het lijkt erop dat het niveau van je cyberbeveiliging te wensen over laat.",
      "redAdviceBody": "Er zijn ernstige zwakke plekken gevonden en het is daarom van cruciaal belang om niet alleen bijkomende analyses uit te (laten) voeren, maar ook onmiddellijk contact op te nemen met een IT-professional om je te laten begeleiden bij het aanpakken van deze zwakke plekken.",
      "emailFormTitle": "De volgende stap? Jouw aanbeveling in PDF ontvangen?",
      "emailFormBody": "<p> Wil je een <strong>uitgebreidere analyse</strong> ontvangen over je resultaten en de <strong>aanbevelingen</strong>, dan kan je het rapport in PDF-vorm ontvangen per <strong>e-mail</strong>. Ook het ontvangen van dit rapport is gratis en vrijblijvend! </p>", 
      "disclaimerCheck": "<span id=\"disclaimerText\" class=\"block text-sm font-semibold text-gray-900\">Ik ga akkoord met de voorwaarden in de <a class=\"text-AXSBlue\" href=\"./disclaimer.html?lang=nl\"> disclaimer</a></span>",
      "newsletterCheck": "Ik ontvang graag berichten wanneer er nieuwigheden zijn en schrijf me in voor de nieuwsbrief.",
      "emailButton": "Ja, stuur me het rapport met mijn testresultaten!",
      "emailPlaceholder": "Bedrijfs e-mail",
      "invalidMail": "Ongeldig e-mailadres!",
      "invalidMailDescr": "U moet een zakelijk e-mailadres opgeven",
      "disclaimerTitle": "Disclaimer",
      "disIntro": "Deze cyberCyberSelfTest is enkel bedoeld voor informatieve doeleinden en als hulpmiddel voor het beoordelen van de huidige status van de cyberbeveiliging op uw netwerk en computer. Het gebruik van deze CyberSelfTest is onderworpen aan de volgende voorwaarden en beperkingen:",
      "dis1Head": "Kostprijs",
      "dis1Body": "Het gebruik van de CyberselfTest is gratis.",
      "dis2Head": "Geen garantie van Beveiliging",
      "dis2Body": "Hoewel deze CyberSelfTest is ontworpen om mogelijke beveiligingsrisico's en zwakke plekken te identificeren, kunnen we geen garantie bieden dat het identificeren van beveiligingsproblemen volledig is. Het is belangrijk om aanvullende beveiligingsmaatregelen te overwegen en professioneel advies in te winnen. ",
      "dis3Head": "Geen vervanging voor Professioneel Advies",
      "dis3Body": "Deze CyberSelfTest is geen vervanging voor beveiligingsadvies van professionals. Het wordt sterk aanbevolen om uw beveiligingsstatus te laten beoordelen door gekwalificeerde experts om eventuele beveiligingsrisico's volledig te begrijpen en aan te pakken.",
      "dis4Head": "Verantwoordelijkheid gebruiker",
      "dis4Body": "Het gebruik van deze CyberSelfTest en de interpretatie van de resultaten is de verantwoordelijkheid van de gebruiker. We zijn niet aansprakelijk voor enige schade, verlies of gevolgen die voortvloeien uit het gebruik van de CyberSelfTestresultaten.",
      "dis5Head": "Privacy en gegevens",
      "dis5Body": "Deze CyberSelfTest kan gegevens verzamelen met betrekking tot uw systeem en netwerk voor analytische doeleinden. Alle gegevens worden vertrouwelijk behandeld en alleen gebruikt voor de beoordeling van de CyberSelfTest. We zullen uw persoonlijke gegevens niet delen of openbaar maken zonder uw toestemming.",
      "dis6Head": "Aanbevelingen en verbeteringen",
      "dis6Body": "De resultaten van de CyberSelfTest kunnen aanbevelingen voor beveiligingsverbeteringen bevatten. Het implementeren van deze aanbevelingen is naar eigen goeddunken van de gebruiker.",
      "dis7Head": "Intellectuele eigendomsrechten",
      "dis7Body": "De inhoud van onze website wordt beschermd door intellectuele eigendomsrechten. U heeft de toelating om de inhoud van onze website te raadplegen voor uw eigen persoonlijk gebruik en voor uw intern zakelijk gebruik. Indien materialen voor u ter beschikking worden gesteld als een download of per e-mail, dan heeft u het recht om die materialen gratis te downloaden en te kopiëren, evenals het recht om deze materialen gratis aan derden ter beschikking te stellen, op voorwaarde dat u deze materialen niet wijzigt. Voor specifieke materialen kan het zijn dat u specifieke rechten wordt toegekend. Dit wordt aangeduid in het betreffende materiaal.",
      "dis8Head": "Eigendomsrecht",
      "dis8Body": "CybertSelfTest.be (en op andere domeinen) is een website en tool van Able bv, met maatschappelijke zetel te B-2800 Mechelen (België), Dellingstraat 28B, ondernemingsnummer 0457.938.087, info@axsguard.com.",
      "disOutro1": "Door gebruik te maken van deze cyberCyberSelfTest, gaat u akkoord met de bovenstaande voorwaarden en beperkingen. Als u niet akkoord gaat met deze voorwaarden, gebruik de CyberSelfTest dan niet.",
      "disOutro2": "<p>Voor vragen of opmerkingen kunt u contact met ons opnemen via <a href=\"mail:info@axsguard.com\" class=\"text-AXSBlue\"> info@axsguard.com </a>.</p>",
      "disOutro3": "Datum van laatste herziening: 27/11/2023",
      "homeButton": "Startpagina",
      "faqTitle": "Veel gestelde vragen",
      "faqq1": "Voor wie is deze CyberSelfTest?",
      "faqa1Intro": "Deze CyberSelfTest kan nuttig zijn voor een breed scala aan doelgroepen, afhankelijk van het specifieke doel en de focus van de CyberSelfTest.",
      "faqa1h1": "Bedrijven en Organisaties:",
      "faqa1b1": "Bedrijven kunnen de CyberSelfTest gebruiken om de cyberbeveiliging van hun interne netwerken en systemen te evalueren. Dit kan helpen bij het identificeren van zwakke plekken en het verbeteren van de beveiligingsmaatregelen.",
      "faqa1h2": "Individuen:",
      "faqa1b2": "Individuele gebruikers, met name degenen die zich bewust zijn van de risico's van cyberdreigingen, kunnen de CyberSelfTest gebruiken om hun eigen computers en apparaten te controleren en verbeteringen aan te brengen in hun persoonlijke beveiligingspraktijken.",
      "faqa1h3": "IT-afdelingen:",
      "faqa1b3": "IT-professionals binnen organisaties kunnen de CyberSelfTest gebruiken als onderdeel van hun periodieke beveiligingsbeoordelingen en audits om de naleving van beveiligingsrichtlijnen te meten. ",
      "faqa1h4": "Cyberbeveiligingsdienstverleners:",
      "faqa1b4": "Bedrijven die gespecialiseerd zijn in cyberbeveiliging kunnen de CyberSelfTest aanbieden als een dienst aan hun klanten om de beveiligingsstatus van hun systemen te evalueren en aanbevelingen te doen voor verbeteringen.",
      "faqa1h5": "Opleidingsinstellingen:",
      "faqa1b5": "Scholen en opleidingsinstellingen kunnen de CyberSelfTest gebruiken als educatief hulpmiddel om studenten te onderwijzen over cyberbeveiligingspraktijken en hen te laten zien hoe ze hun eigen beveiliging kunnen verbeteren.",
      "faqq2": "Ik krijg een rood scherm bij het starten van de test. Wat nu?",
      "faqa2": "Deze test evalueert de nieuwste en meest recente bekende cyberbedreigingen. Hierdoor worden telkens nieuwe kwetsbaarheden getest, waardoor het gedrag van de browser moeilijk te voorspellen is. Door eerst op 'Details' en later op 'Bezoek deze site' te klikken, is het mogelijk om de verkeerde labeling door de browser te omzeilen. We kunnen je verzekeren dat deze test 100% veilig is en dat er niets op je machine wordt geïnstalleerd. Meer informatie is beschikbaar op de thuispagina en in de disclaimer.",
      "faqq3": "Waar kan ik terecht voor meer info?",
      "faqa3": "<p> Contacteer ons via <a href=\"mailto:support@axsguard.com\" class=\"text-AXSBlue\">support@axsguard.com</a> of bel op <a href=\"tel:+3215504400\" class=\"text-AXSBlue\">+32 15 50 44 00</a>",
      "faqq5": "Wat kost de test?",
      "faqa5": "De test is volledig gratis.",
      "thankyouPage": "Bedankt voor het uitvoeren van de CyberSelfTest!",
      "emailSuccessTitle": "Proficiat met je eerste stap in de veilige richting!",      
      "emailSuccessBody1": "<p> Je hebt het initiatief genomen om kritisch te kijken naar je cyberbeveiliging. Dat is een eerste belangrijke stap naar bewustwording van potentiële online gevaren. Omdat wij in staat zijn samen een veiligere digitale omgeving te creëren voor iedereen, is het een <strong> goed idee om deze test (niet je resultaten) te delen met vrienden, collega’s, bekenden </strong>. </p>",
      "emailSuccessBody2": "<p> Als je vragen hebt over de test of over je testresultaten, neem dan gerust contact op met AXS Guard, de initiatiefnemer van de CyberSelfTest. Mail <a href=\"mailto:support@axsguard.com\" class=\"text-AXSBlue\">support@axsguard.com</a>. We staan klaar om je te helpen uw cybersecurity te verbeteren en uw online veiligheid te waarborgen. </p>",
      "emailSuccessSpam": "Controleer zeker uw spammap indien het rapport niet in uw inbox aankomt",
      "emailSuccessSafeDay": "Een veilige dag toegewenst!",
      "emailLearnMore": "Meer informatie over AXS Guard:",
      "blogBox": "Blog",
      "blogTitle": "Blijf op de hoogte met de AXS Guard-blog",
      "siteBox": "Website",
      "siteTitle": "Meer weten over onze oplossingen door de site te bezoeken",
      "partnersBox": "Partners",
      "partnersTitle": "Geïnteresseerd? Vind een van onze vele partners",
      "pdfScoreText": "Jouw algemene score: ",
      "pdfGreenIntroTitle": "Het lijkt erop dat jij goed bezig bent!",
      "pdfGreenIntro1": "De CyberSelfTest heeft weinig tot geen zwakke punten aan het licht gebracht. Gefeliciteerd! Jij bent je bewust van het belang van een goede cyber hygiëne.",
      "pdfGreenIntro2": "Houd er echter rekening mee dat dit slechts een momentopname is en geen garantie biedt voor de toekomst. We raden je aan om diepere analyses uit te voeren die nog meerdere beveiligingsaspecten onderzoeken.",
      "pdfGreenIntro3": "In elk geval geven we je graag een beknopte checklist mee. Het is geen overbodige luxe om de elementen van het lijstje op regelmatige basis te evalueren of bepaalde acties te herhalen.",
      "pdfOrangeIntroTitle": "Het lijkt erop dat er ruimte tot verbetering is.",
      "pdfOrangeIntro1": "Op basis van de CyberSelfTest zijn er zwakheden in je cyberbeveiliging aan de oppervlakte gekomen die om aandacht vragen. Het is duidelijk dat er het één en ander aangepakt moet worden. Want ja… het moet gezegd: je cyber hygiëne kan beter!",
      "pdfOrangeIntro2": "Houd er echter rekening mee dat het resultaat van de CyberSelfTest slechts een momentopname betreft en geen garanties biedt, maar een indicatie. De resultaten zijn uiteraard ook niet even accuraat als een grondige analyse. We raden je aan om diepere analyses uit te voeren die nog meerdere beveiligingsaspecten onderzoeken.",
      "pdfOrangeIntro3": "We zien verschillende beveiligingsniveaus: de scores zijn uiteenlopend. De subtests met de laagste scores - zeker wanneer deze lager dan 50% bedragen - vragen om dringende aandacht. Wil je je cyber security naar een beter niveau brengen, dan zijn dit de punten die je urgent zal moeten aanpakken",
      "pdfOrangeIntro4": "Hou er rekening mee dat deze test en adviezen zijn gericht op het voorkomen van aanvallen en aanverwanten. Aangezien de beveiliging zwakheden vertoont, is het mogelijk dat je reeds gecompromitteerd bent.  Om zeker te zijn, is ons advies opnieuw om cyber security experts in te huren.",
      "pdfOrangeIntro5": "In elk geval geven we je graag een beknopte checklist mee. Het is geen overbodige luxe om de elementen van het lijstje op regelmatige basis te evalueren of bepaalde acties te herhalen, ongeacht of je goed scoort op bepaalde onderdelen! Cyberbeveiliging is een werkwoord.",
      "pdfRedIntroTitle": "Oops. Het lijkt erop dat het niveau van je cyberbeveiliging te wensen over laat.",
      "pdfRedIntro1": "Er zijn ernstige zwakke plekken gevonden en het is daarom van cruciaal belang om niet alleen bijkomende analyses uit te (laten) voeren, maar ook onmiddellijk contact op te nemen met een IT-professional om je te laten begeleiden bij het aanpakken van deze zwakke plekken.",
      "pdfRedIntro2": "Houd er echter rekening mee dat het resultaat van de CyberSelfTest slechts een momentopname betreft en geen garanties biedt, maar een indicatie. De resultaten zijn uiteraard ook niet even accuraat als een grondige analyse. We raden je aan om diepere analyses uit te voeren die nog meerdere beveiligingsaspecten onderzoeken.",
      "pdfRedIntro3": "Het zou dus kunnen dat het nog erger gesteld is met je beveiligingsniveau dan deze test laat uitschijnen. De subtests met de laagste scores - zeker wanneer deze lager dan 50% bedragen - vragen om dringende aandacht. Wil je je cyber security naar een beter niveau brengen, dan zijn dit de punten die je urgent zal moeten aanpakken.",
      "pdfRedIntro4": "Let op: deze test en adviezen zijn gericht op het voorkomen van aanvallen en aanverwanten. Aangezien de beveiliging zwakheden vertoont, is het mogelijk dat je reeds gecompromitteerd bent.  Om zeker te zijn, is ons advies opnieuw om cyber security experts in te huren.",
      "pdfRedIntro5": "In elk geval geven we je graag een beknopte checklist mee. Het is geen overbodige luxe om de elementen van het lijstje op regelmatige basis te evalueren of bepaalde acties te herhalen, ongeacht of je goed scoort op bepaalde onderdelen! Cyberbeveiliging is een werkwoord.",
      "pdfSmallCheckListTitle": "Aandachtspunten / mini-checklist:",
      "pdfSmallCheckList1": "Spamfilters up-to-date?",
      "pdfSmallCheckList2": "EDR / XDR in place?",
      "pdfSmallCheckList3": "Antivirusoplossing up-to-date?",
      "pdfSmallCheckList4": "Gebruik steeds HTTPS",
      "pdfSmallCheckList5": "Authenticiteit van websites en afzenders controleren",
      "pdfSmallCheckList6": "DNS filtering aan?",
      "pdfSmallCheckList7": "Firewall config OK?",
      "pdfSmallCheckList8": "Monitor je ongebruikelijke activiteiten op je netwerk?",
      "pdfSmallCheckList9": "Niet wachten met het installeren van (software) updates",
      "pdfSmallCheckList10": "SSL inspectie",
      "pdfLargeCheckListTitle": "Nog meer tips:",
      "pdfLargeCheckList1": "Organiseer op regelmatige basis threat awareness trainings voor je medewerkers. Niet enkel voor technisch personeel! Om zoveel mogelijk risico’s te vermijden, maak je best al je medewerkers bewust van online gevaren. Dat doe je door hen sceptisch te leren kijken naar boodschappen en afzenders en hun anomalieën te leren herkennen.  1 x per jaar of 1 x per 2 jaar is zeker wenselijk. Mensen vergeten immers snel, cybercrime evolueert ook snel en er is allicht ook verloop in je organisatie.",
      "pdfLargeCheckList2": "Doe aan pentesting of zet eens een ethical hacker in. Het identificeert zwakheden en kan een echte eye-opener zijn voor gebruikers.",
      "pdfLargeCheckList3": "Laat geregeld eens een analyse uitvoeren van  je netwerk, je infrastructuur en je beveiligingsniveau.",
      "pdfLargeCheckList4": "Het naleven van de NIS 2-richtlijn kan je hanteren als ‘best practice’, ook al behoort je organisatie niet tot één van de sectoren die wettelijk verplicht zijn om eraan te voldoen.",
      "pdfLargeCheckList5": "'Better safe than sorry': ook als het op cyber security aankomt, is voorkomen beter dan genezen. Een goede preventie-strategie kan je veel leed besparen.",
      "pdfLargeCheckList6": "Kies voor Managed Security Services: cyber security is complexe materie die aan een snel tempo evolueert. Laat het dagdagelijkse beheer van je infrastructuur en netwerk over aan experts.",
      "pdfIntroMSSP": "Neem contact op met AXS Guard: Observe & Protect is de Managed Services formule van AXS Guard die geschikt is voor kleine en grote bedrijven. Bovendien koppelen wij indien gewenst je organisatie met plezier aan een geschikte IT-partner die je noden begrijpt en je IT-omgeving kent als zijn broekzak.",
      "pdfTestCategoryExpl": "Meer uitleg:",
      "pdfTestDisclosure": "Wat hebben we getest?",
      "pdfRecommendations": "Expertenadvies:",
      "pdfOutro": "Heb je vragen over cyberbeveiliging, over deze aanbevelingen of wil je in contact komen met ervaren IT-integrators? Neem contact op met info@axsguard.com."
    }
}